import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Map, Marker, Popup, TileLayer } from "react-leaflet";
import "./Contact.css";
import API from "../../modules/api";
import { BasePage, BlockContent } from "../../common";
import { useForm, ValidationError } from "@formspree/react";

export default function Contact() {
  const isIE = /*@cc_on!@*/ false || !!document.documentMode;
  const [state, handleSubmit] = useForm("xvolpjka");
  const submittedTitle =
    "Thank you for your message, we'll be in touch shortly!";
  const [data, setData] = useState([]);

  const getData = useCallback(async () => {
    const response = await API.getContent(API.CONTENT_TYPES.SETTINGS);
    setData(response);


    console.log(response.contactAddress);
  }, [setData]);

  useEffect(() => {
    getData();
  }, []);

  const location = useMemo(() => {
    return data?.location ? [data?.location?.lat, data?.location?.lng] : [0, 0];
  }, [data]);

  return (
    <BasePage>
      <div className="content">
        <div className="contact max-width">
          <div className="contact-form">
            {!state.succeeded && <h1>Contact Us</h1>}
            {state.succeeded && (
              <div className="contact-submitted">{submittedTitle}</div>
            )}
            {!state.succeeded && (
              <form onSubmit={handleSubmit} className="contact-form">
                <input
                  className="contact-field"
                  id="name"
                  type="text"
                  name="name"
                  required
                  placeholder="Name (required)"
                />
                <ValidationError
                  prefix="Name"
                  field="name"
                  errors={state.errors}
                />
                <input
                  className="contact-field"
                  id="phone"
                  type="text"
                  name="phone"
                  placeholder="Phone"
                />
                <ValidationError
                  prefix="Phone"
                  field="phone"
                  errors={state.errors}
                />
                <input
                  className="contact-field"
                  id="email"
                  type="email"
                  name="email"
                  required
                  placeholder="Email Address (required)"
                />
                <ValidationError
                  prefix="Email"
                  field="email"
                  errors={state.errors}
                />
                <textarea
                  className="contact-message-field"
                  id="message"
                  name="message"
                  required
                  placeholder="Message"
                />
                <ValidationError
                  prefix="Message"
                  field="message"
                  errors={state.errors}
                />
                <button
                  type="submit"
                  disabled={state.submitting}
                  className="contact-submit"
                >
                  Submit
                </button>
              </form>
            )}
            {state.errors?.length > 0 && (
              <div style={{ color: "red" }}>{state.errors[0].message}</div>
            )}
          </div>
          <div style={{ width: 20 }} />
          <div className="contact-homes">
            <h2>Contact Details</h2>
            {!isIE && (
              <Map
                center={location}
                zoom={13}
                scrollWheelZoom={false}
                dragging={false}
              >
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                <Marker position={location}>
                  <Popup>{"Whitebeach"}</Popup>
                </Marker>
              </Map>
            )}
            <BlockContent data={data?.contactAddress} />
          </div>
        </div>
      </div>
    </BasePage>
  );
}
