import React, { useState, useEffect } from "react";
import "./PickerBlock.css";
import API from "../../modules/api";
import { useHistory } from "react-router-dom";
import { useCallback } from "react";

export default function PickerBlock({ className = "", data = {} }) {
  const history = useHistory();

  const onClick = useCallback(
    (item) => {
      const link = item.target;
      if (link) {
        if (link.lastIndexOf("http", 0) === 0) {
          window.location.href = link;
        } else {
          history.push(link);
        }
      }
    },
    [history]
  );


  return (
    <div
      className={"picker" + className}
      style={{
        height: data?.height,
        maxHeight: data?.height,
        minHeight: data?.height,
      }}
    >
      {data?.pickerItems?.map((item, i) => {
        return (
          <div key={i} className="picker-item" onClick={() => onClick(item)}>
            <img src={API.urlForAsset(item.image)} alt={item.title} />
            <div className="picker-item-title">
              <div>{item.title}<b className="picker-item-arrow">›</b></div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
