import React, { useEffect, useState } from "react";
import "./News.css";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { BasePage, Carousel, BlockContent, ContactBar } from "../../common";
import NewsArchivePicker from "./NewsArchivePicker";
import NewsArticleSummary from "./NewsArticleSummary";
import API from "../../modules/api";

export default function News() {
  const [selectedArchive, setSelectedArchive] = useState(
    moment().format("YYYY")
  );
  const [years, setYears] = useState({});
  const history = useHistory();
  const [articles, setArticles] = useState([]);
  const [data, setData] = useState({});

  async function getData() {
    const response = await API.getContent(API.CONTENT_TYPES.NEWS);
    setData(response);
    const response2 = await API.getArticles();
    setArticles(response2);
  }

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (articles == null) {
      return;
    }
    const newYears = articles.reduce((newYears, article) => {
      const year = moment(article.publishedAt).format("YYYY");
      if (newYears[year] == null) {
        newYears[year] = [article];
      } else {
        newYears[year].push(article);
      }
      return newYears;
    }, {});
    setYears(newYears);
	const keys = Object.keys(newYears)
    const newSlectedArchive = keys[keys.length-1];
    setSelectedArchive(newSlectedArchive);
  }, [articles, setSelectedArchive]);

  function onArticleClicked(slug) {
    history.push("/news/" + slug.current);
  }

  return (
    <BasePage>
      {!!data?.carousel && <Carousel _data={data?.carousel} />}
      <div className="content">
        {!!data?.body && <BlockContent data={data?.body} />}
        <div className="news-container">
          <div className="news-articles">
            {years[selectedArchive] &&
              years[selectedArchive].map((article, i) => {
                return (
                  <NewsArticleSummary
                    key={i}
                    article={article}
                    onClick={onArticleClicked}
                  />
                );
              })}
          </div>
          <NewsArchivePicker
            selectedIndex={selectedArchive}
            onItemClick={(x) => setSelectedArchive(x)}
            years={years}
          />
        </div>
      </div>
      {!!data?.showContact && <ContactBar />}
    </BasePage>
  );
}
