import React from "react";
import SanityBlockContent from "@sanity/block-content-to-react";
import {
  Brochure,
  CareHomePicker,
  TextBlock,
  ImageBlock,
  SingleImage,
  VideoBlock,
  TourBlock,
  PickerBlock,
} from ".";

const serializers = {
  list: (props) => <ul className="sanity-list">{props.children}</ul>,
  listItem: (props) => <li className="sanity-list-item">{props.children}</li>,
  types: {
    brochure: ({ node }) => {
      return <Brochure data={node} />;
    },
    homePicker: () => {
      return <CareHomePicker />;
    },
    textBlock: ({ node }) => {
      return <TextBlock data={node} />;
    },
    imageBlock: ({ node }) => {
      return <ImageBlock data={node} />;
    },
    singleImage: ({ node }) => {
      return <SingleImage data={node} single />;
    },
    videoBlock: ({ node }) => {
      return <VideoBlock data={node} />;
    },
    tourBlock: ({ node }) => {
      return <TourBlock data={node} />;
    },
    pickerBlock: ({ node }) => {
      return <PickerBlock data={node} />;
    },
    block: (props) => {
      const { style = "normal" } = props.node;
      if (style === "blockquote") {
        const string = props.children[0] ?? "";
        const isPink = string.includes("[pink]");
        if (props.children[0]) {
          props.children[0] = props.children[0].replace(/\[.*?\]/g, "");
        }
        return (
          <blockquote style={{ color: isPink ? "#f4bcca" : "#5e6bb4" }}>
            {props.children}
          </blockquote>
        );
      }
      return SanityBlockContent.defaultSerializers.types.block(props);
    },
  },
};

export default function BlockContent({ data, children, className }) {
  return (
    <div className={"max-width-extra sanity " + className}>
      <SanityBlockContent
        blocks={data}
        projectId="emynwqv3"
        dataset="production"
        serializers={serializers}
      >
        {children}
      </SanityBlockContent>
    </div>
  );
}
