
import React from 'react';
import './TextBlock.css'
import SanityBlockContent from '@sanity/block-content-to-react';
import API from '../../modules/api'


export default function TextBlock({
  className,
  data,
}) {

  return (
    <div className={"tb max-width-extra " + className}>
      <div className="tb-content">
        <div className="tb-left">
          {!!data?.title && <h1>{data?.title}</h1>}
          {!!data?.image && <img src={API.urlForAsset(data?.image)}/>}
        </div>
        <SanityBlockContent
          blocks={data?.body}
          projectId='emynwqv3'
          dataset='production'/>
      </div>
    </div>
  );
}